import noUiSlider from 'nouislider';

$(document).ready(function () {

    /*
    STICKY MENU
    */
    $('header').sticky({
        topSpacing:0,
        zIndex : 50
    });

    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function() {
        /*Ouvre le menu*/
        $('#nav').slideToggle();

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();
    })
    /* Ouverture des sous-menu */
    if ($(window).width() < 1025) {
        $('#nav ul li a').on('click', function (e) {
            if ($(this).siblings('ul').length > 0) {
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
                $(this).unbind("click");
            }
        })
    }

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
       let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    // let requiredInput = $("input").attr("required", "true");
    let requiredInput = $("input[required='required']");
    requiredInput.each(function() {
        let id = $(this).attr('id');
        let label =  $("label[for='"+ id +"']")
        label.addClass('required');
    });


    var expanded = false;
    function showCheckboxes() {
        let checkboxes = document.getElementById("checkboxes");
        if (!expanded) {
            checkboxes.style.display = "block";
            expanded = true;
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    $('.selectBox').on('click', function (){
        $(this).siblings('#checkboxes').slideToggle();
    })

    $('.multiselect input').on('change', function (){
        let multiselectParent = $(this).closest('.multiselect');
        let inputs = multiselectParent.find('input:checked');
        completeInputsValue(inputs, multiselectParent);
    })

    //Au chargement de la page
    let multiselect = $('.multiselect')
    if (multiselect.length > 0) {
        multiselect.each(function () {
            let inputs = $(this).find('input:checked');
            completeInputsValue(inputs, $(this));
        })
    }

    function completeInputsValue(inputs, multiselectParent) {
        let text = '';
        const textEl = multiselectParent.find('p');
        if(inputs.length > 0){
            multiselectParent.find('.all-inputs').attr("checked", false);
            let inputs = multiselectParent.find('input:checked');
            inputs.each(function (index) {
                text = text + $(this).data('value') + (index !== inputs.length - 1 ? '/ ' : '');
            })
            textEl.text(text)
            textEl.addClass('active');
        }
        else{
            textEl.text(multiselectParent.data('placeholder'));
            multiselectParent.find('.all-inputs').attr("checked", true);
            textEl.removeClass('active');
        }
    }


    /* no ui slider */
    function createSliders() {
        var sliders = document.getElementsByClassName('slider-range');

        for (var i = 0; i < sliders.length; i++) {
            const step = sliders[i].getAttribute('data-step');
            const type = sliders[i].getAttribute('data-type');
            let range = document.getElementById($(this).attr('id'));
            let valueMin = $('#' + type + 'MinData').data(type);
            let valueMax = $('#' + type + 'MaxData').data(type);
            let valueMinFiltres = $('#' + type + 'MinFiltres').data(type);
            let valueMaxFiltres = $('#' + type + 'MaxFiltres').data(type);

            noUiSlider.create(sliders[i], {
                range: {
                    'min': valueMin,
                    'max': valueMax
                },
                step: parseInt(step),
                start: [valueMinFiltres, valueMaxFiltres],
                connect: true,
                format: wNumb({
                    decimals: 0,
                    thousand: ' ', // thousand delimiter
                })

            })
            let inputs = [
                document.getElementById('filters_' + type + 'Min'),
                document.getElementById('filters_' + type + 'Max')
            ];

            let labels = [
                document.getElementById('filters_' + type + 'MinLabel'),
                document.getElementById('filters_' + type + 'MaxLabel')
            ];

            sliders[i].noUiSlider.on('start', function (values, handle) {
                inputs[handle].value = values[handle];
            });
            sliders[i].noUiSlider.on('update', function (values, handle) {
                inputs[handle].value = values[handle];
                labels[handle].textContent = values[handle];
                $(inputs[handle]).change();

            });
        }
    }

    createSliders();

    /*
    ONGLETS DEROULANT
     */
    $('#filtre').on('click', function (e) {
        e.preventDefault();
        if($('#containerFiltre').hasClass('hidden')){
            $('#containerFiltre').show('slow');
            $('#containerFiltre').removeClass('hidden')
            $('#filtre').text('Cacher les filtres')
        }
        else{
            $('#containerFiltre').addClass('hidden')
            $('#containerFiltre').hide('slow');
            $('#filtre').text('Filtrer les véhicules')
        }
    })

    function hideAllTooltip(){
        let width = $('.price-container').width()
        $('.js-tooltip-suggest-content').fadeOut()
        if($(window).width() >= 425){
            $('.wide-js-tooltip').css('width', (width-50))
        }
        $('.js-tooltip-suggest').attr('open',false)
    }

    $(document).on('click', function (e) {
        let el = $(e.target)
        if(el.closest('details').length < 1){
            hideAllTooltip()
        }
        e.stopPropagation()
    })

    $(document).on('click','.js-tooltip-suggest-button', function (e) {
        let btn = $(e.target)
        let ctn = btn.closest('.js-tooltip-suggest')

        let isOpen = ctn.attr('open') === 'open'
        if (isOpen) { // on cache
            ctn.find('.js-tooltip-suggest-content').fadeOut()
        } else { // on affiche
            hideAllTooltip()
            ctn.find('.js-tooltip-suggest-content').fadeIn()
        }
    })


    /*
    BTN ACCUEIL FIXE
     */

    $('.tel').on('click', function (e) {
        e.preventDefault();
        if($('#containerTel').hasClass('hidden')){
            $('#containerTel').removeClass('hidden')
            $('#containerTel').show()
        }
        else {
            $('#containerTel').hide()
            $('#containerTel').addClass('hidden')
        }
    })

    $('.montre').on('click', function (e) {
        e.preventDefault();
        if($('#containerMontre').hasClass('hidden')){
            $('#containerMontre').removeClass('hidden')
            $('#containerMontre').show()
        }
        else {
            $('#containerMontre').hide()
            $('#containerMontre').addClass('hidden')
        }
    })
    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    $('#slider-vehicule-thumb .swiper-slide').on('click', function (){
        console.log(this);
        $(this).siblings().removeClass('swiper-slide-active');
        $(this).addClass('swiper-slide-active');
    });


    /*
    POPUP
     */
    // $('.popup-open').on('click', function (e) {
    //     e.preventDefault();
    //     let id = $(this).data('id');
    //     $('#popup-'+id).fadeIn().css('display', 'grid')
    // })
    /* Fermeture de la popup */

    // $('.popup-close').on('click', function() {
    //     $('.popup').fadeOut();
    // })
    // $('.popup').on('click', function(e) {
    //     let popup = $('.popup-content');
    //     if (!popup.is(e.target) && popup.has(e.target).length === 0) {
    //         $('.popup').fadeOut();
    //     }
    // });
    // $(document).keyup(function(e) {
    //     if (e.keyCode === 27) {
    //         $('.popup').fadeOut();
    //     }
    // });

});

/*
SLIDERS
 */
import Swiper, {Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Thumbs, Autoplay ]);

/* Slider hero */
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    navigation: {
        nextEl: '.swiper-hero_next',
        prevEl: '.swiper-hero_prev',
    }
})

const galleryVehiculeStock = new Swiper('#slider-vehicule-stock', {
    spaceBetween: 10,
    slidesPerView: 1,
    loop: true,
    autoplay:{
        delay:3000,
    },
    speed:1000,
    navigation: {
        nextEl: '.swiper-vehicule-stock_next',
        prevEl: '.swiper-vehicule-stock_prev',
    },
    breakpoints: {
        790: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1280: {
            slidesPerView: 4,
        },
    }
});

const galleryAvis = new Swiper('#slider-avis', {
    spaceBetween: 40,
    slidesPerView: 1,
    loop: true,
    navigation: {
        nextEl: '.swiper-avis_next',
        prevEl: '.swiper-avis_prev',
    },
    breakpoints: {
        790: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    }
});

const galleryThumbs = new Swiper('#slider-vehicule-thumb', {
    spaceBetween: 10,
    centeredSlides: true,
    slidesPerView: 3,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 4,
    breakpoints: {
        790: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 2,
        },
        1400: {
            slidesPerView: 3,
        },
        1600: {
            slidesPerView: 4,
        }
    }
});
const galleryTop = new Swiper('#slider-vehicule-main', {
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-vehicule-next',
        prevEl: '.swiper-vehicule-prev',
    },
    loop: true,
    loopedSlides: 4,
    thumbs: {
        swiper: galleryThumbs,
    },
});

import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/* savoir */
/* Histoire */
function animateFrom(elem, direction) {
    direction = direction || 1;
    let x = 0,
        y = direction * 100;
    if (elem.classList.contains("fromLeft")) {
        x = -100;
        y = 0;
    } else if (elem.classList.contains("fromRight")) {
        x = 100;
        y = 0;
    }
    else if (elem.classList.contains("fromBottom")) {
        x = 0;
        y = 100;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
    });
}

function fadeIn(elem) {
    gsap.to(elem, {
        duration: 1,
        opacity: 1,
        autoAlpha: 1,
        stagger: .75,
    })
}

function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function () {
    $('.anim-left').addClass('fromLeft')
    $('.anim-right').addClass('fromRight')
    $('.anim-bottom').addClass('fromBottom')
    if ($(window).width() > 1024) {
        $('#timeline section:nth-of-type(odd)').addClass('fromLeft')
    } else {
        $('#timeline section:nth-of-type(odd)').addClass('fromRight')
    }
    $('#timeline section:nth-of-type(even)').addClass('fromRight')


    gsap.utils.toArray(".anim-right").forEach(function (elem) {
        hide(elem);
        ScrollTrigger.create({
            trigger: elem,
            start: 'top center',
            onEnter: function () {
                animateFrom(elem)
            },
        });
    });
    gsap.utils.toArray(".anim-left").forEach(function (elem) {
        hide(elem);
        ScrollTrigger.create({
            trigger: elem,
            start: 'top center',
            onEnter: function () {
                animateFrom(elem)
            },
        });
    });
    gsap.utils.toArray(".anim-bottom").forEach(function (elem) {
        hide(elem);
        ScrollTrigger.create({
            trigger: elem,
            start: 'top center',
            onEnter: function () {
                animateFrom(elem)
            },
        });
    });
    gsap.utils.toArray("#timeline .section").forEach(function (elem) {
        hide(elem);
        ScrollTrigger.create({
            trigger: elem,
            start: 'top center',
            onEnter: function () {
                animateFrom(elem)
            },
            onEnterBack: function() { animateFrom(elem, -5
            ) },
            onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
        });
    });

    let point = gsap.timeline({
        scrollTrigger: {
            trigger: "#timeline",
            pin: '#point',
            scrub: 1,
            start: 'top center',
            end: "bottom center"
        }
    }).to('#point', {
        duration: 1,
        ease: 'none',
    })

    /**
     * Bouton voir plus
     * @param bouton
     * @param object param : url, filters, frame
     */
    const nbVehiculesTotal =  $('.elements-filters-list').data('total');
    window.seeMore = function(bouton, param) {
        if($(bouton) == undefined || param == undefined) return;
        $(bouton).css("display", "none");

        // Offset + limit
        var offset = 0;
        if($(bouton).attr("data-offset") != undefined) offset = parseFloat($(bouton).attr("data-offset"));
        var limit = 0;
        if($(bouton).attr("data-limit") != undefined) limit = parseFloat($(bouton).attr("data-limit"));

        var filters = param.filters;

        $.ajax({
            url: param.url,
            method: 'post',
            data: {filters:filters, offset:offset, limit:limit},
            success: function(data){
                if(data == undefined || data == "") return false;
                $('.' + param.frame).append(data.html);
                $(bouton).attr("data-offset", offset + limit);
                if (nbVehiculesTotal > offset + limit) {
                    $(bouton).css("display", "block");
                }
            }
        });
    }

});

