/* ----- CSS ----- */

// Project CSS
require('@fortawesome/fontawesome-free/css/all.min.css');
require ('swiper/swiper-bundle.css');
require('../css/schumpp.css');
require('../css/boutique.css');
require('nouislider/dist/nouislider.css');

import './../scripts/colorbox/colorbox.css';

/* ----- Javascript ----- */

const $ = require('jquery');
global.$ = global.jQuery = $;
require('jquery-ui');
require('jquery-sticky');
require ('swiper');

// Project JS
import './../scripts/colorbox/jquery.colorbox.js'
import './schumpp.js';
import './form_filter_product.js';
import './boutique.js';