let ajaxCall = null;

export function refreshProductContainer(formFilterProduct)
{
    if (ajaxCall != null) {
        ajaxCall.abort();
        ajaxCall = null;
    }

    $('.elements-filters-list').addClass('is-loading');
    let params = [];
    ajaxCall = $.ajax({
        method: 'POST',
        url: Routing.generate($('.elements-filters-list').data('route'), params),
        data: formFilterProduct.serialize(),
        dataType: "json"
    })
        .done(function (data) {
            $('.elements-filters-list').removeClass('is-loading');
            console.log(data)
            if(data.html==''){
                $('.elements-filters-list-container').html('Aucun résultat disponible pour ces caractéristiques.');
            }
            else{
                $('.elements-filters-list-container').html(data.html);
            }

        })
        .fail(function (data) {
            console.log("Request failed: " + data.status + " " + data.message);
        });
}
